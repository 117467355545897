@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
body {
  font-family: "Poppins", sans-serif;
}
.login {
  height: 100vh;
  background-image: url("./images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.background {
  background-image: url("./images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 120vh;
  overflow-x:hidden;
}

.container,
.logincard {
  background-color: rgba(253, 252, 252, 0.5) !important;
  /* opacity: 0.1; */
}
.logout {
  color: rgb(27, 26, 26);
  font-weight: bold;
  text-decoration: none;
}
thead {
  background-color: #007aff !important;
}
h6 {
  font-weight: bold;
  font-size: 24px;
}
th {
  opacity: 0.8;
  font-weight: 100 !important;
}
.form-control {
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
 
  .holder {
    /* flex-direction: column; */
     overflow-x:hidden;
  }
  .table {
    font-size: 0.7em;
  }
  .chart-width {
    width: 300px;
  }
  .form-control {
    font-size: 8px;
  }
  .btn {
    font-size: 12px;
  }
}
